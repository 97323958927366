import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export const AboutPageTemplate = ({ page }) => {
  console.log(page);
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section commissions">
              <h1>{page.frontmatter.title}</h1>
              <HTMLContent className="content" content={page.html} />
              <h2>previous work</h2>
              <div className="columns is-multiline releases">
                {page.frontmatter.previousWork?.map(({ image, link }) => (
                  <a href={link}>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image,
                      }}
                    />
                  </a>
                ))}
              </div>
              <h2>pricing</h2>
              <div className="pricing">
                <table>
                  {page.frontmatter.pricing.map((row) => (
                    <tr>
                      <td className="desc">
                        <strong>{row.description}</strong>
                      </td>
                      <td className="price">{row.amount}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  console.log(data);

  return (
    <Layout>
      <AboutPageTemplate contentComponent={HTMLContent} page={post} />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        previousWork {
          link
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        pricing {
          description
          amount
        }
      }
    }
  }
`;
